import Link from 'next/link';

import Clickable from 'components/Clickable';
import Container from 'components/Container';
import Typography from 'components/Typography';
import LogoSVG from 'icons/logo.svg';
import styles from './Error.module.css';

interface ErrorProps {
  statusCode?: number;
  errorMessage?: string;
}

export default function Error({
  statusCode = 500,
  errorMessage = 'An unknown error occured',
}: ErrorProps) {
  return (
    <Container className={styles.root}>
      <LogoSVG className={styles.logo} />
      <Typography gutter={1} variant="sub">
        Error: {statusCode}
      </Typography>
      <Typography gutter={1} variant="big">
        {errorMessage}
      </Typography>
      <Link href="/">
        <Clickable className={styles.back}>
          <Typography variant="sub">Go to Jacksons.se</Typography>
        </Clickable>
      </Link>
    </Container>
  );
}
